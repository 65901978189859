import cx from 'classnames';

import { Icon } from './Icon';

type AlertVariant = 'info' | 'success' | 'warning' | 'error';

type AlertProps<AsType extends React.ElementType> = React.ComponentPropsWithoutRef<AsType> & {
  as?: AsType;
  variant?: AlertVariant;
  title?: string;
  show?: boolean;
  onHide?: () => void;
};

export function Alert<AsType extends React.ElementType = 'p'>({
  as,
  className,
  variant = 'info',
  title,
  show = true,
  onHide,
  children,
  ...rest
}: AlertProps<AsType>) {
  const Component = as || 'p';

  return show ? (
    <Component className={cx('p-3 flex rounded-xl relative', variants[variant], className)} role="alert" {...rest}>
      {onHide ? (
        <button
          type="button"
          className="absolute right-3 top-3 z-10 cursor-pointer text-xxs-medium text-secondary"
          onClick={onHide}
        >
          Hide
        </button>
      ) : null}

      {variant === 'info' ? (
        <span className="mr-2 inline-flex">
          <Icon name="Info" className="text-[1.25rem]" />
        </span>
      ) : null}

      {variant === 'warning' ? (
        <span className="mr-2 inline-flex">
          <Icon name="Warning" className="text-[1.25rem]" />
        </span>
      ) : null}
      <span className="inline-flex max-w-[56.25rem] flex-col">
        {title ? <span className="mb-1 text-s-bold">{title}</span> : null}
        <span className="text-s-regular">{children}</span>
      </span>
    </Component>
  ) : null;
}

const variants: Record<AlertVariant, string> = {
  info: 'bg-blue-50 border border-blue-100',
  success: 'alert alert-success shadow-lg',
  warning: 'bg-yellow-50 border border-yellow-100',
  error: ' bg-red-400 border border-red-400',
};
